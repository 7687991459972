import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'personalizacion-alarma',
    pathMatch: 'full'
  },
  { path: 'programacion-equipos', loadChildren: './programacion-equipos/programacion-equipos.module#ProgramacionEquiposPageModule' },
  { path: 'tabla-consumos', loadChildren: './tabla-consumos/tabla-consumos.module#TablaConsumosPageModule' },
  { path: 'solucion-problemas', loadChildren: './solucion-problemas/solucion-problemas.module#SolucionProblemasPageModule' },
  { path: 'cableado', loadChildren: './cableado/cableado.module#CableadoPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'personalizacion-alarma', loadChildren: './personalizacion-alarma/personalizacion-alarma.module#PersonalizacionAlarmaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'personalizacion-alarma/codigo-propietario', loadChildren: './personalizacion-alarma/codigo-propietario/codigo-propietario.module#CodigoPropietarioPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'personalizacion-alarma/codigo-instalador', loadChildren: './personalizacion-alarma/codigo-instalador/codigo-instalador.module#CodigoInstaladorPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'personalizacion-alarma/definicion-usuarios', loadChildren: './personalizacion-alarma/definicion-usuarios/definicion-usuarios.module#DefinicionUsuariosPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'personalizacion-alarma/prog-transmisor-sensor', loadChildren: './personalizacion-alarma/prog-transmisor-sensor/prog-transmisor-sensor.module#ProgTransmisorSensorPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'personalizacion-alarma/prog-estoy-mevoy', loadChildren: './personalizacion-alarma/prog-estoy-mevoy/prog-estoy-mevoy.module#ProgEstoyMevoyPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'personalizacion-alarma/selec-funcionalidad-transmisores', loadChildren: './personalizacion-alarma/selec-funcionalidad-transmisores/selec-funcionalidad-transmisores.module#SelecFuncionalidadTransmisoresPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh', loadChildren: './programacion-equipos/centrales-mpxh/centrales-mpxh.module#CentralesMpxhPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/teclados-led-miniteclado-mpxh', loadChildren: './programacion-equipos/teclados-led-miniteclado-mpxh/teclados-led-miniteclado-mpxh.module#TecladosLedMinitecladoMpxhPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/teclados-lcd', loadChildren: './programacion-equipos/teclados-lcd/teclados-lcd.module#TecladosLcdPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/receptores', loadChildren: './programacion-equipos/receptores/receptores.module#ReceptoresPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/sensores-infrarrojos-pasivos', loadChildren: './programacion-equipos/sensores-infrarrojos-pasivos/sensores-infrarrojos-pasivos.module#SensoresInfrarrojosPasivosPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/detectores-rotura-vidrio', loadChildren: './programacion-equipos/detectores-rotura-vidrio/detectores-rotura-vidrio.module#DetectoresRoturaVidrioPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/detectores-humo', loadChildren: './programacion-equipos/detectores-humo/detectores-humo.module#DetectoresHumoPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/barreras-infrarrojas-detectores-proximidad', loadChildren: './programacion-equipos/barreras-infrarrojas-detectores-proximidad/barreras-infrarrojas-detectores-proximidad.module#BarrerasInfrarrojasDetectoresProximidadPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/sensores-perimetrales-concentradores', loadChildren: './programacion-equipos/sensores-perimetrales-concentradores/sensores-perimetrales-concentradores.module#SensoresPerimetralesConcentradoresPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/automatizacion', loadChildren: './programacion-equipos/automatizacion/automatizacion.module#AutomatizacionPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/sirenas-senializacion', loadChildren: './programacion-equipos/sirenas-senializacion/sirenas-senializacion.module#SirenasSenializacionPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/comunicadores-monitoreo', loadChildren: './programacion-equipos/comunicadores-monitoreo/comunicadores-monitoreo.module#ComunicadoresMonitoreoPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/llamadores-controladores', loadChildren: './programacion-equipos/llamadores-controladores/llamadores-controladores.module#LlamadoresControladoresPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/avisadores-controladores-sms', loadChildren: './programacion-equipos/avisadores-controladores-sms/avisadores-controladores-sms.module#AvisadoresControladoresSmsPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/tiempo-entrada', loadChildren: './programacion-equipos/centrales-mpxh/tiempo-entrada/tiempo-entrada.module#TiempoEntradaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/tiempo-salida', loadChildren: './programacion-equipos/centrales-mpxh/tiempo-salida/tiempo-salida.module#TiempoSalidaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/programacion-tiempo-alarma', loadChildren: './programacion-equipos/centrales-mpxh/programacion-tiempo-alarma/programacion-tiempo-alarma.module#ProgramacionTiempoAlarmaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zonas-dos-cuatro-condicionales', loadChildren: './programacion-equipos/centrales-mpxh/zonas-dos-cuatro-condicionales/zonas-dos-cuatro-condicionales.module#ZonasDosCuatroCondicionalesPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/reloj-tiempo-real', loadChildren: './programacion-equipos/centrales-mpxh/reloj-tiempo-real/reloj-tiempo-real.module#RelojTiempoRealPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/activacion-automatica', loadChildren: './programacion-equipos/centrales-mpxh/activacion-automatica/activacion-automatica.module#ActivacionAutomaticaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/modo-ahorro-energia', loadChildren: './programacion-equipos/centrales-mpxh/modo-ahorro-energia/modo-ahorro-energia.module#ModoAhorroEnergiaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/inhibicion-disparos-sabotaje', loadChildren: './programacion-equipos/centrales-mpxh/inhibicion-disparos-sabotaje/inhibicion-disparos-sabotaje.module#InhibicionDisparosSabotajePageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/anunciador-entrada', loadChildren: './programacion-equipos/centrales-mpxh/anunciador-entrada/anunciador-entrada.module#AnunciadorEntradaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zona-salida-sirena-b', loadChildren: './programacion-equipos/centrales-mpxh/zona-salida-sirena-b/zona-salida-sirena-b.module#ZonaSalidaSirenaBPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zona-salida-sirenas-a-b', loadChildren: './programacion-equipos/centrales-mpxh/zona-salida-sirenas-a-b/zona-salida-sirenas-a-b.module#ZonaSalidaSirenasABPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zona-incendio', loadChildren: './programacion-equipos/centrales-mpxh/zona-incendio/zona-incendio.module#ZonaIncendioPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zona-robo-normal', loadChildren: './programacion-equipos/centrales-mpxh/zona-robo-normal/zona-robo-normal.module#ZonaRoboNormalPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zona-veinticuatro-horas', loadChildren: './programacion-equipos/centrales-mpxh/zona-veinticuatro-horas/zona-veinticuatro-horas.module#ZonaVeinticuatroHorasPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zona-robo-rapida', loadChildren: './programacion-equipos/centrales-mpxh/zona-robo-rapida/zona-robo-rapida.module#ZonaRoboRapidaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zona-panico', loadChildren: './programacion-equipos/centrales-mpxh/zona-panico/zona-panico.module#ZonaPanicoPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/zona-sabotaje', loadChildren: './programacion-equipos/centrales-mpxh/zona-sabotaje/zona-sabotaje.module#ZonaSabotajePageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/tiempo-sonorizacion-zona-b', loadChildren: './programacion-equipos/centrales-mpxh/tiempo-sonorizacion-zona-b/tiempo-sonorizacion-zona-b.module#TiempoSonorizacionZonaBPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/volumen-sirena-lqh', loadChildren: './programacion-equipos/centrales-mpxh/volumen-sirena-lqh/volumen-sirena-lqh.module#VolumenSirenaLQHPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/centrales-mpxh/resumen-programaciones-centrales', loadChildren: './programacion-equipos/centrales-mpxh/resumen-programaciones-centrales/resumen-programaciones-centrales.module#ResumenProgramacionesCentralesPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'programacion-equipos/sistemas-antientraderas-pulsadores-aviso', loadChildren: './programacion-equipos/sistemas-antientraderas-pulsadores-aviso/sistemas-antientraderas-pulsadores-aviso.module#SistemasAntientraderasPulsadoresAvisoPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas/centrales', loadChildren: './solucion-problemas/guia-problemas/centrales/centrales.module#CentralesPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas/sensores', loadChildren: './solucion-problemas/guia-problemas/sensores/sensores.module#SensoresPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas/teclados', loadChildren: './solucion-problemas/guia-problemas/teclados/teclados.module#TecladosPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas/sirena-lqh', loadChildren: './solucion-problemas/guia-problemas/sirena-lqh/sirena-lqh.module#SirenaLqhPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas/receptores-remotos-sensores-inalambricos', loadChildren: './solucion-problemas/guia-problemas/receptores-remotos-sensores-inalambricos/receptores-remotos-sensores-inalambricos.module#ReceptoresRemotosSensoresInalambricosPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas/llamadores-controladores-telefonicos', loadChildren: './solucion-problemas/guia-problemas/llamadores-controladores-telefonicos/llamadores-controladores-telefonicos.module#LlamadoresControladoresTelefonicosPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas/avisadores-controladores-sms', loadChildren: './solucion-problemas/guia-problemas/avisadores-controladores-sms/avisadores-controladores-sms.module#AvisadoresControladoresSmsPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas/comunicadores-monitoreo', loadChildren: './solucion-problemas/guia-problemas/comunicadores-monitoreo/comunicadores-monitoreo.module#ComunicadoresMonitoreoPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/guia-problemas', loadChildren: './solucion-problemas/guia-problemas/guia-problemas.module#GuiaProblemasPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/uso-tlcd', loadChildren: './solucion-problemas/uso-tlcd/uso-tlcd.module#UsoTlcdPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/inhibicion-disparo-sabotaje', loadChildren: './solucion-problemas/inhibicion-disparo-sabotaje/inhibicion-disparo-sabotaje.module#InhibicionDisparoSabotajePageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'solucion-problemas/gw3-mpxh', loadChildren: './solucion-problemas/gw3-mpxh/gw3-mpxh.module#Gw3MpxhPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/detectores-humo', loadChildren: './tabla-consumos/detectores-humo/detectores-humo.module#DetectoresHumoPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/detectores-rotura-vidrio', loadChildren: './tabla-consumos/detectores-rotura-vidrio/detectores-rotura-vidrio.module#DetectoresRoturaVidrioPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/detectores-movimiento', loadChildren: './tabla-consumos/detectores-movimiento/detectores-movimiento.module#DetectoresMovimientoPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/barreras-sensores-proximidad', loadChildren: './tabla-consumos/barreras-sensores-proximidad/barreras-sensores-proximidad.module#BarrerasSensoresProximidadPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/sensores-apertura', loadChildren: './tabla-consumos/sensores-apertura/sensores-apertura.module#SensoresAperturaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/sirenas-senializacion', loadChildren: './tabla-consumos/sirenas-senializacion/sirenas-senializacion.module#SirenasSenializacionPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/llamadores-controladores', loadChildren: './tabla-consumos/llamadores-controladores/llamadores-controladores.module#LlamadoresControladoresPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/receptores-inalambricos', loadChildren: './tabla-consumos/receptores-inalambricos/receptores-inalambricos.module#ReceptoresInalambricosPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/modulos-gsm-gprs', loadChildren: './tabla-consumos/modulos-gsm-gprs/modulos-gsm-gprs.module#ModulosGsmGprsPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/luces-emergencia', loadChildren: './tabla-consumos/luces-emergencia/luces-emergencia.module#LucesEmergenciaPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/automatizacion', loadChildren: './tabla-consumos/automatizacion/automatizacion.module#AutomatizacionPageModule' },
  // tslint:disable-next-line: max-line-length
  { path: 'tabla-consumos/teclados-paneles', loadChildren: './tabla-consumos/teclados-paneles/teclados-paneles.module#TecladosPanelesPageModule' },
















];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
